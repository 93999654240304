import { loadMicroApp } from 'qiankun'

// 登录后微应用 myApps我的引用列表；  appLists所有应用列表
const appData = JSON.parse(sessionStorage.getItem('appData'))

// 加载的微应用以及状态，正在加载的值为loading，加载完成后为loaded
const microApps = {}

// 共享组件缓存
const share = {
  // 共享组件加载微应用失败和找不到组件显示的组件
  'master-error':  () => import('@/views/components/common/error'),
  'master-noComp':  () => import('@/views/components/common/noComp'),
  'master-noApp':  () => import('@/views/components/common/noApp'),
  'master-noBuy':  () => import('@/views/components/common/noBuy'),
}
// 组件帮组
const help = {}

// 共享组件的包裹器
window.__SHARE_WRAPPER__ = {
  install: function(Vue) {
    Vue.component('wrapper', () => import('@/views/components/Wrapper') )
  }
}

/**
 * 创建微应用的dom容器
 * @param {String} appName
 * @returns
 */
const generaterContainer = function(appName) {
    const microContainer = document.querySelector('#micro-container')
    let container = microContainer.querySelector(`#${appName}`)
    if(container) {
      return container
    }
    container = document.createElement("div")
    container.setAttribute("id", appName)
    microContainer.appendChild(container)
    return container
}

async function doLoadApp(appKey) {
  // 通过appKe获取到app
  const app = appData.myApps.find(item => item.appKey == appKey)
  if(!app) {
    if(appData.appLists.some(item => item.appKey == appKey)) {
     return Promise.resolve(share['master-noBuy'])
   } else {
     return Promise.resolve(share['master-noApp'])
   }
  }
  
  console.log('app entry-------------');
  console.log(app.entry);
  
  // 设置应用加载中
  microApps[app.appKey] = 'loading'
  const microApp = loadMicroApp({
    name: app.appKey,
    entry: app.entry,
    container: generaterContainer(app.appKey)
  }, {
    prefetch: false, // false：取消资源预加载
    sandbox: true,
    singular: false,  // 是否单实例
  })
  try {
    await microApp.loadPromise
    console.log('===================== load app')
    // 设置应用已加载
    microApps[app.appKey] = 'loaded'
  } catch( e) {
    console.error(e)
    // 设置应用加载失败
    microApps[app.appKey] = 'error'
  }
  return Promise.resolve()
}

/**
 * 加载微应用
 * @param {String} appKey 微应用key
 * @returns
 */
export async function loadAppByKey(appKey) {
  return doLoadApp(appKey)
}


/**
 * 注册共享组件
 * @param {Object} app  微应用对象，格式： {name: "compay", appDesc: "公司"}
 * @param {Array} components  注入的组件列表
 */
window.__SHARE_REGISTER__ = function(app, components) {
  for (let key in components) {
    const compName = `${app.name}-${key}`
    const component = components[key].component
    share[compName] = component
    delete components[key].component
  }
  help[app.name] = {
    appDesc: app.appDesc,
    components: components
  }
}

/**
 * 异步获取共享组件
 * @param {String} appKey
 * @param {String} compKey
 * @returns
 */
function asyncGetComp(appKey, compKey){
  const compName = `${appKey}-${compKey}`
  var p = new Promise(function(resolve, reject){
    if(share[compName]) {
      resolve(share[compName])
    } else if('loaded' == microApps[appKey]) {
      // 微服务已经加载完成的情况处理--找不到对应的组件
      resolve(share['master-noComp'])
    }else if('error' == microApps[appKey])  {
      // 微应用加载失败处理
      resolve(share['master-error'])
    } else {
      // 正在加载微应用--递归
      setTimeout(function(){
        resolve(asyncGetComp(appKey, compKey))
      }, 100);
    }
  })
  return p;
}

/**
 * 获取微应用的共享组件
 * @param {*} appKey 微应用key
 * @param {*} compKey 共享组件key
 * @returns
 */
export const __SHARE_INSTALL__ = async function(appKey, compKey) {
  if(!microApps.hasOwnProperty(appKey)) {
    const com = await loadAppByKey(appKey)
    if(com) {
      return Promise.resolve(com)
    }
  }

  return asyncGetComp(appKey, compKey)
}

window.__SHARE_HELP__ = help


// const apis = {}
// window.__SHARE_API__ = function(api) {
//   apis.fn = api
// }

window.apis = {}
