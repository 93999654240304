<template>
  <div id="app" style="height: 100vh" v-loading="isLoading"
    element-loading-text="拼命加载中"
    >
    <transition>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
    <div id='micro-container'/>

    <div v-if="isLoading">&nbsp;</div>
  </div>
</template>

<script>
import { loadAppByKey } from '@/utils/share'
import { getLocalCurrenAppKey, setLocalCurrenAppKey } from '@/utils/auth'

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      isLoading: true
    }
  },
  methods: {
    async initPage() {
      this.isLoading = true

      let appKey = getLocalCurrenAppKey()
      if(!appKey) {
        appKey = this.$route.params.appKey
        appKey && setLocalCurrenAppKey(appKey)
      }

      if(appKey) {
        await loadAppByKey(appKey)
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.initPage()
  },
}
</script>

<style lang="scss">
#app {

}
</style>
