import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Platform = () => import(/* webpackChunkName: "parser-example" */'@/views/platform/index')

const routes = [
  {
	path: '/',
	name: 'Platform',
	component: Platform
  },
  {
	path: '/:appKey',
	component: null
  }
]

const router = new VueRouter({
	// mode: 'history',
	// base: '',
	routes
})

router.beforeEach((to, from, next) => {
	console.log('===============master to.path', to.path)
	next()
})

export default router
